import React, { useEffect, useState } from "react"

const AnimatedOverlay = props => {
  const [cmNavOverlayHeight, setCmNavOverlayHeight] = useState(0)

  useEffect(() => {
    if (props.menuStatus) {
      setCmNavOverlayHeight(props.menuHeight.current.offsetHeight + 6)
    } else {
      setCmNavOverlayHeight(0)
    }
  }, [props.menuStatus, props.menuHeight])

  return (
    <div
      className="cm-nav-overlay"
      data-wf-ignore=""
      style={{
        height: cmNavOverlayHeight + "px",
        display: "block",
      }}
    >
      {props.children}
    </div>
  )
}
export default class Header extends React.Component {
  constructor(props) {
    super(props)

    this.mobileMenu = React.createRef()

    this.state = {
      maxWidth: { maxWidth: "728px" },
      menuVisibility: false,
      translateValue: this.menuVisibility ? `0px` : `-377.3594px`,
    }
  }

  toggleMobileMenu = () => {
    this.setState(prevState => ({
      menuVisibility: !prevState.menuVisibility,
      translateValue: !prevState.menuVisibility ? `0px` : `-377.3594px`,
    }))
  }

  render() {
    return (
      <div
        data-collapse="medium"
        data-animation="default"
        data-duration="400"
        className="main-nav w-nav"
      >
        <div className="cm-nav-padding w-container">
          <a href="/" className="brand w-nav-brand">
            {/* <img
              src="images/logo-martenica.png"
              width="250"
              alt=""
              className="image"
            /> */}
            <img src="images/logo-easter.png" width="250" alt="" className="image" />
            {/* <img src="images/logo.png" width="220" alt="" className="image" /> */}
            {/* <img
              src="images/logo-christmas-hat.png"
              width="250"
              alt=""
              className="image"
            /> */}
          </a>
          <nav role="navigation" className="nav-menu w-nav-menu">
            <a href="/" className="nav-link w-nav-link w--current">
              Начало
            </a>
            <a href="/#daily-menu" className="w-nav-link">
              Меню
            </a>
            {/* <a href="/#news" className="w-nav-link">
              Новини
            </a> */}
            <a href="/#punktove" className="w-nav-link">
              Пунктове
            </a>
            <a href="/#pricing" className="w-nav-link">
              Цени
            </a>
            <a href="/#contact" className="w-nav-link">
              Контакти
            </a>
          </nav>
          <div
            className={`menu-button cm-nav-button ${
              this.state.menuVisibility ? "w--open" : ""
            }`}
            onClick={this.toggleMobileMenu}
          >
            <div className="icon-2 w-icon-nav-menu"></div>
          </div>
        </div>
        <AnimatedOverlay
          menuHeight={this.mobileMenu}
          menuStatus={this.state.menuVisibility}
        >
          <nav
            ref={this.mobileMenu}
            role="navigation"
            className={`nav-menu w-nav-menu-cm w--nav-menu-open`}
            style={{
              transform: `translateY(${this.state.translateValue}) ${
                !this.state.menuVisibility ? "matrix(1, 0, 0, 1, 0, 0)" : ""
              }`,
              transition: `transform 400ms ease 0s`,
              display: "block !important",
            }}
          >
            <a
              href="/"
              className="nav-link w-nav-link w--current w--nav-link-open"
              style={this.state.maxWidth}
            >
              Начало
            </a>
            <a
              href="/#daily-menu"
              className="w-nav-link w--nav-link-open"
              style={this.state.maxWidth}
            >
              Меню
            </a>
            <a
              href="/#punktove"
              className="w-nav-link w--nav-link-open"
              style={this.state.maxWidth}
            >
              Пунктове
            </a>
            <a
              href="/#pricing"
              className="w-nav-link w--nav-link-open"
              style={this.state.maxWidth}
            >
              Цени
            </a>
            <a
              href="/#contact"
              className="w-nav-link w--nav-link-open"
              style={this.state.maxWidth}
            >
              Контакти
            </a>
          </nav>
        </AnimatedOverlay>
      </div>
    )
  }
}
